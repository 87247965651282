import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./plugins/bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Axios from "axios";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";

import "leaflet/dist/leaflet.css";

Vue.config.productionTip = false;

function tokenInterceptor() {
  Axios.interceptors.request.use(
    config => {
      config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
}

Vue.use(VueKeyCloak, {
  config: {
    realm: "crash-hotspots",
    url: "https://idp.itsdigitallab.com/auth/",
    clientId: "ch-app"
  },
  onReady: keycloak => {
    tokenInterceptor();
    if(!("ch-role" in keycloak.idTokenParsed)) {
      keycloak.logout()
    }

    localStorage.setItem("token", keycloak.token);
    new Vue({
      el: "#app",
      router,
      store,
      template: "<App/>",
      render: h => h(App)
    });
  }
});
