<template>
  <div>
    <b-spinner
      v-if="!this.$store.state.enableHotSpotOptions"
      id="loading"
      type="grow"
      label="Loading..."
      style="width: 3rem; height: 3rem"
    ></b-spinner>

    <div v-else>
      <div v-if="showStreetView">
        <iframe
          id="street-view-photo-sphere"
          width="100%"
          height="100%"
          frameborder="0"
          style="border: 0"
          allowfullscreen
          :src="streetViewURL"
        >
        </iframe>

        <div v-if="showCloseOverlay" id="close-street-view-icon">
          <b-icon
            id="close-overlay"
            icon="x"
            font-scale="2.25"
            :style="closeIconStyleObject"
            @click="hideStreetView"
          >
          </b-icon>
        </div>
      </div>

      <div id="map"></div>

      <div v-if="displayCountermeasures" id="countermeasures">
        <div class="cm-child" id="cm-1">
          <div class="cm-child-header">Infrastructure - CM 1</div>
          <div class="cm-child-body">
            <div class="cm-child-body-recommendation">
              {{
                this.roadCharacteristics.countermeasures.primary.countermeasure
              }}
            </div>
            <div class="cm-child-body-accuracy">
              <div class="accuracy-title">Cost savings:</div>
              <div class="accuracy-value">
                {{
                  (
                    this.roadCharacteristics.countermeasures.primary
                      .probability * 100
                  ).toFixed(2)
                }}%
              </div>
            </div>
            <div class="cm-child-body-compliance">
              <div class="compliance-info">
                <ul class="cm-child-body-ul">
                  <li v-for="message in primaryCompliance" :key="message">
                    {{ message }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="cm-child" id="cm-2">
          <div class="cm-child-header">Infrastructure - CM 2</div>
          <div class="cm-child-body">
            <div class="cm-child-body-recommendation">
              {{
                this.roadCharacteristics.countermeasures.secondary
                  .countermeasure
              }}
            </div>
            <div class="cm-child-body-accuracy">
              <div class="accuracy-title">Cost savings:</div>
              <div class="accuracy-value">
                {{
                  (
                    this.roadCharacteristics.countermeasures.secondary
                      .probability * 100
                  ).toFixed(2)
                }}%
              </div>
            </div>

            <div class="cm-child-body-compliance">
              <div class="compliance-info">
                <ul class="cm-child-body-ul">
                  <li v-for="message in secondaryCompliance" :key="message">
                    {{ message }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="cm-child" id="cav-1" v-if="displayPrimaryCAVMessage">
          <div class="cm-child-header cav">CAV - CM 1</div>
          <div class="cm-child-body">
            <div class="cm-child-body-recommendation">
              Message(s):
              <ul class="cm-child-body-ul">
                <li v-for="message in primaryMessages" :key="message">
                  {{ message }}
                </li>
              </ul>
            </div>
            <div class="cm-child-body-accuracy">
              <div class="accuracy-title">Cost savings:</div>
              <div class="accuracy-value">
                {{
                  (
                    this.roadCharacteristics.cav_messages.primary.probability *
                    100
                  ).toFixed(2)
                }}%
              </div>
            </div>

            <div class="cm-child-body-compliance">
              <div class="compliance-info">
                ITIS code(s):
                <ul class="cm-child-body-ul">
                  <li v-for="message in primaryItis" :key="message">
                    {{ message }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="cm-child-body-optional">
              <div class="optional-message-sets">
                <div v-if="primaryMessageSetsExist">
                  Optional message set(s):
                </div>
                <ul class="cm-child-body-ul">
                  <li v-for="message in primaryMessageSets" :key="message">
                    {{ message }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="cm-child" id="cav-2" v-if="displaySecondaryCAVMessage">
          <div class="cm-child-header cav">CAV - CM 2</div>
          <div class="cm-child-body">
            <div class="cm-child-body-recommendation">
              Message(s):
              <ul class="cm-child-body-ul">
                <li v-for="message in secondaryMessages" :key="message">
                  {{ message }}
                </li>
              </ul>
            </div>
            <div class="cm-child-body-accuracy">
              <div class="accuracy-title">Cost savings:</div>
              <div class="accuracy-value">
                {{
                  (
                    this.roadCharacteristics.cav_messages.secondary
                      .probability * 100
                  ).toFixed(2)
                }}%
              </div>
            </div>

            <div class="cm-child-body-compliance">
              <div class="compliance-info">
                ITIS code(s):
                <ul class="cm-child-body-ul">
                  <li v-for="message in secondaryItis" :key="message">
                    {{ message }}
                  </li>
                </ul>
              </div>

              <div class="cm-child-body-optional">
                <div class="optional-message-sets">
                  <div v-if="secondaryMessageSetsExist">
                    Optional message set(s):
                  </div>
                  <ul class="cm-child-body-ul">
                    <li v-for="message in secondaryMessageSets" :key="message">
                      {{ message }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <mapSideBar
        v-if="displayDetailedView"
        @closeDetailedView="closeDetailedView"
        @showCountermeasures="showCountermeasures"
        :location="location"
        :roadCharacteristics="roadCharacteristics"
        :vruStatistics="vruStatistics"
      />

      <div id="date-selector">
        <b-form-datepicker
          id="from-date"
          class="date-picker"
          v-model="fromDate"
          placeholder="From Date"
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }"
          :min="fromDateMin"
          :max="fromDateMax"
          :reset-value="fromDateMin"
          locale="en"
          offset="-90"
          reset-button
          reset-button-variant="info"
        ></b-form-datepicker>

        <b-form-datepicker
          id="to-date"
          class="date-picker"
          v-model="toDate"
          placeholder="To Date"
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }"
          :min="toDateMin"
          :max="toDateMax"
          :reset-value="toDateMax"
          locale="en"
          offset="-90"
          reset-button
          reset-button-variant="info"
        ></b-form-datepicker>
      </div>
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import axios from "axios";
import { mapState } from "vuex";
import MapSideBar from "@/components/MapSideBar.vue";

export default {
  name: "Map",
  components: {
    mapSideBar: MapSideBar,
  },
  computed: {
    ...mapState([
      "selectedIncidentTypes",
      "selectedVRUs",
      "showStreetView",
      "selectedRoadClasses",
    ]),
    streetViewURL: function () {
      return (
        "https://www.google.com/maps/embed/v1/streetview?key=" +
        this.$keycloak.tokenParsed["maps-key"] +
        "&location=" +
        this.location.lat +
        "," +
        this.location.lng +
        "&heading=210&pitch=10&fov=35"
      );
    },
    closeIconStyleObject: function () {
      return {
        "--close-overlay-color": "#ffffff80",
        "--close-overlay-hover-color": "#ffffffCC",
      };
    },
    displayPrimaryCAVMessage: function () {
      if (this.$store.state.featureToggles["cav_messages"]) {
        return (
          this.roadCharacteristics.cav_messages &&
          this.roadCharacteristics.cav_messages.primary
        );
      } else {
        return false;
      }
    },
    displaySecondaryCAVMessage: function () {
      if (this.$store.state.featureToggles["cav_messages"]) {
        return (
          this.roadCharacteristics.cav_messages &&
          this.roadCharacteristics.cav_messages.secondary
        );
      } else {
        return false;
      }
    },
    primaryMessages: function () {
      return this.roadCharacteristics.cav_messages.primary.countermeasure;
    },
    secondaryMessages: function () {
      return this.roadCharacteristics.cav_messages.secondary.countermeasure;
    },
    primaryCompliance: function () {
      return [
        this.roadCharacteristics.countermeasures.primary.compliance.join(
          " and "
        ) + " compliant",
      ];
    },
    primaryItis: function () {
      return this.roadCharacteristics.cav_messages.primary.compliance.map((x) =>
        x.join(", ")
      );
    },
    secondaryCompliance: function () {
      return [
        this.roadCharacteristics.countermeasures.secondary.compliance.join(
          " and "
        ) + " compliant",
      ];
    },
    secondaryItis: function () {
      return this.roadCharacteristics.cav_messages.secondary.compliance.map(
        (x) => x.join(", ")
      );
    },
    primaryMessageSets: function () {
      return this.roadCharacteristics.cav_messages.primary.optional_messages;
    },
    primaryMessageSetsExist: function () {
      return (
        this.roadCharacteristics.cav_messages.primary.optional_messages.length >
        0
      );
    },
    secondaryMessageSets: function () {
      return this.roadCharacteristics.cav_messages.secondary.optional_messages;
    },
    secondaryMessageSetsExist: function () {
      return (
        this.roadCharacteristics.cav_messages.secondary.optional_messages
          .length > 0
      );
    },
  },
  data() {
    return {
      map: undefined,
      mapCenter: [30.2672, -97.7431],
      displayDetailedView: false,
      displayCountermeasures: false,
      location: {},
      roadCharacteristics: {},
      vruStatistics: {},
      initialDateRangeUpdated: false,
      showCloseOverlay: false,
      fromDate: "",
      toDate: "",
      fromDateMin: "",
      fromDateMax: "",
      toDateMin: "",
      toDateMax: "",
      crashSpots: {},
      topCrashSpots: [], // Used for storing the list of hotspots to be re-used later to filter by jurisdiction
      selectedTopCrashSpots: [],
      normalizedCrashCountMap: {},
      incidentTypes: [],
      vulnerableRoadUsers: [],
      topCrashSpotLeafetIdMapper: [],
      hotspotTypesMarkerGroup: [],
      vruMarkersGroup: [],
      incidentTypesMarkersGroup: [],
      iconSize: 48,
      markerIconRearEnd: L.Icon.extend({
        options: {
          iconUrl: "marker-purple.png",
          iconSize: [this.iconSize / 3, this.iconSize / 2],
        },
      }),
      markerIconSingleVehicle: L.Icon.extend({
        options: {
          iconUrl: "marker-olive.png",
          iconSize: [this.iconSize / 3, this.iconSize / 2],
        },
      }),
      markerIconWrongWay: L.Icon.extend({
        options: {
          iconUrl: "marker-reddish.png",
          iconSize: [this.iconSize / 3, this.iconSize / 2],
        },
      }),
      cycleIcon: L.Icon.extend({
        options: {
          iconUrl: "cycle.png",
          iconSize: [this.iconSize / 3, this.iconSize / 2],
        },
      }),
      motorbikeIcon: L.Icon.extend({
        options: {
          iconUrl: "motorbike.png",
          iconSize: [this.iconSize / 3, this.iconSize / 2],
        },
      }),
      pedestrianIcon: L.Icon.extend({
        options: {
          iconUrl: "pedestrian.png",
          iconSize: [this.iconSize / 3, this.iconSize / 2],
        },
      }),
    };
  },
  watch: {
    showStreetView: function (value) {
      if (!value) {
        this.showCloseOverlay = false;
      } else {
        // Enable with a deplay because the street
        // takes a few milliseconds to render
        setTimeout(() => {
          this.showCloseOverlay = true;
        }, 400);
      }
    },
    selectedIncidentTypes: function () {
      this.plotIncidentTypes(this.incidentTypes);
    },
    selectedVRUs: function () {
      this.plotVRUSpots(this.vulnerableRoadUsers);
    },
    fromDate: function () {
      this.toDateMin = this.fromDate;
      if (this.toDate === undefined || this.toDate === "") {
        this.toDate = this.fromDateMax;
      } else {
        if (this.initialDateRangeUpdated) {
          this.getHotspots();
        }
      }
    },
    toDate: function () {
      this.fromDateMax = this.toDate;
      if (this.fromDate === undefined || this.fromDate === "") {
        this.makeToast(
          "Please select starting date",
          "Date selection error",
          "danger"
        );
      } else {
        if (this.initialDateRangeUpdated) {
          this.getHotspots();
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    selectedRoadClasses: function (value) {
      this.filterHotspotsByJurisdiction(value);
    },
  },
  methods: {
    initializeMap: function () {
      this.map = L.map("map", {
        center: this.mapCenter,
        zoom: 12.5,
      });
      this.map.zoomControl.setPosition("bottomright");
      this.map.options.minZoom = 12;

      var mapURL =
        "https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png";
      var tileLayer = L.tileLayer(mapURL, {
        maxZoom: 19,
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      });
      tileLayer.addTo(this.map);

      this.hotspotTypesMarkerGroup = L.layerGroup().addTo(this.map);
      this.incidentTypesMarkersGroup = L.layerGroup().addTo(this.map);
      this.vruMarkersGroup = L.layerGroup().addTo(this.map);
    },
    getHotspots: function () {
      let requestUrl = "";
      if (this.$store.state.featureToggles["cav_messages"]) {
        let url = new URL(
          this.$store.state.apiUrl + "/hotspots/top-crash-spots/"
        );
        if (this.fromDate !== undefined && this.toDate !== undefined) {
          url.searchParams.append("start_date", this.fromDate);
          url.searchParams.append("end_date", this.toDate);
        }
        url.searchParams.append("cav_messages", true);

        if (this.$store.state.featureToggles["new_recommender"]) {
          url.searchParams.append("new_recommender", true);
        }
        requestUrl = url.href;
      } else {
        requestUrl = this.$store.state.apiUrl + "/hotspots/top-crash-spots/?";
        if (this.fromDate !== undefined && this.toDate !== undefined) {
          requestUrl +=
            "start_date=" + this.fromDate + "&end_date=" + this.toDate;
        }

        if (this.$store.state.featureToggles["new_recommender"]) {
          requestUrl += "&new_recommender=true";
        }
      }

      axios
        .get(requestUrl)
        .then((response) => {
          this.crashSpots = response.data;

          this.topCrashSpots = [];
          this.incidentTypes = [];
          this.vulnerableRoadUsers = [];

          var latitudes = [];
          var longitudes = [];
          var crashCounts = [];

          Object.keys(this.crashSpots).forEach((element) => {
            var coord = JSON.parse(element.replace("(", "[").replace(")", "]"));

            if (
              Object.keys(this.crashSpots[element]).includes("top_crash_spots")
            ) {
              latitudes.push(coord[0]);
              longitudes.push(coord[1]);

              // Add crash counts to be used for normalization later
              // (because normalization requires the entire set)
              crashCounts.push(
                this.crashSpots[element]["top_crash_spots"].crash_count
              );

              this.topCrashSpots.push({
                location: {
                  lat: coord[0],
                  lng: coord[1],
                },
                count: this.crashSpots[element]["top_crash_spots"].crash_count,
                risk_index:
                  this.crashSpots[element]["top_crash_spots"].risk_index,
                road_classes:
                  this.crashSpots[element]["top_crash_spots"].road_classes[0],
              });
            }

            // 'selectedTopCrashSpots' will be copy of 'topCrashSpots' during initial load
            // 'selectedTopCrashSpots' will be used for manipluating/filtering by jurisdiction
            // 'topCrashSpots' will serve as data storage to re-filter to avoid making recurring API calls
            this.selectedTopCrashSpots = this.topCrashSpots;

            if (Object.keys(this.crashSpots[element]).includes("incident_type"))
              this.incidentTypes.push({
                location: {
                  lat: coord[0],
                  lng: coord[1],
                },
                wrong_way_crashes: Object.keys(
                  this.crashSpots[element].incident_type
                ).includes("wrong_way_crashes")
                  ? this.crashSpots[element].incident_type.wrong_way_crashes
                  : 0,
                ramp_rear_end_crashes: Object.keys(
                  this.crashSpots[element].incident_type
                ).includes("ramp_related_rear_end_crashes")
                  ? this.crashSpots[element].incident_type
                      .ramp_related_rear_end_crashes
                  : 0,
                ramp_single_vehicle_crashes: Object.keys(
                  this.crashSpots[element].incident_type
                ).includes("ramp_related_single_vehicles_crashes")
                  ? this.crashSpots[element].incident_type
                      .ramp_related_single_vehicles_crashes
                  : 0,
              });

            if (
              Object.keys(this.crashSpots[element]).includes(
                "vulnerable_road_users"
              )
            )
              this.vulnerableRoadUsers.push({
                location: {
                  lat: coord[0],
                  lng: coord[1],
                },
                pedestrian: Object.keys(
                  this.crashSpots[element].vulnerable_road_users
                ).includes("pedestrian")
                  ? this.crashSpots[element].vulnerable_road_users.pedestrian
                  : 0,
                bicyclist: Object.keys(
                  this.crashSpots[element].vulnerable_road_users
                ).includes("bicyclist")
                  ? this.crashSpots[element].vulnerable_road_users.bicyclist
                  : 0,
                motorcyclist: Object.keys(
                  this.crashSpots[element].vulnerable_road_users
                ).includes("motorcyclist")
                  ? this.crashSpots[element].vulnerable_road_users.motorcyclist
                  : 0,
              });
          });

          // Normalize previously stored crash counts between 1, 10
          var normalizedValues = this.normalizeCrashCounts(crashCounts, 1, 10);
          for (let i = 0; i < normalizedValues.length; i++) {
            this.normalizedCrashCountMap[crashCounts[i]] = normalizedValues[i];
          }

          this.$store.dispatch("updateEnableHotSpotOptions", true);
          this.initialDateRangeUpdated = true;

          this.mapCenter = [
            latitudes.reduce((a, b) => a + b, 0) / latitudes.length,
            longitudes.reduce((a, b) => a + b, 0) / longitudes.length,
          ];

          // Inititalize the map after hotspot data has been fetched
          this.$nextTick(() => {
            var div = document.getElementById("map");
            if (div !== null) {
              if (this.map === undefined) {
                this.initializeMap();
              } else {
                this.map.panTo({
                  lat: this.mapCenter[0],
                  lng: this.mapCenter[1],
                });
              }

              this.plotTopCrashSpots(this.topCrashSpots);
              this.plotIncidentTypes(this.incidentTypes);
              this.plotVRUSpots(this.vulnerableRoadUsers);
            }
          });
        })
        .catch((err) => console.log(err));
    },
    plotTopCrashSpots: function (hotspots) {
      var marker = undefined;
      this.hotspotTypesMarkerGroup.clearLayers();

      hotspots.forEach((element) => {
        var options = {
          color: this.getColor(element.risk_index),
          radius: this.getRadius(element.count),
          opacity: 1,
          fillOpacity: 0.5,
          smoothFactor: 1,
        };

        marker = L.circle(
          [element.location.lat, element.location.lng],
          options
        ).bindTooltip(
          "crash count: " +
            element.count +
            "<br>" +
            "risk index: " +
            Number.parseFloat(element.risk_index, 2).toPrecision(2) +
            "<br>" +
            "location: " +
            element.location.lat +
            ", " +
            element.location.lng
          // "<br>" +
          // "road class: " +
          // element.road_classes
        );
        marker.addTo(this.map).on("click", this.onHotspotClick);
        this.hotspotTypesMarkerGroup.addLayer(marker);

        this.topCrashSpotLeafetIdMapper[marker._leaflet_id] =
          "(" + element.location.lat + ", " + element.location.lng + ")";
      });
    },
    plotIncidentTypes: function (hotspots) {
      var rampRearEndIcon = new this.markerIconRearEnd({ iconSize: [24, 30] });
      var rampSingleVehicleIcon = new this.markerIconSingleVehicle({
        iconSize: [24, 30],
      });
      var wrongWayIcon = new this.markerIconWrongWay({ iconSize: [24, 30] });

      this.incidentTypesMarkersGroup.clearLayers();
      var marker = undefined;
      hotspots.forEach((element) => {
        if (
          this.selectedIncidentTypes.includes("ramp_rear_end_crashes") &&
          element.ramp_rear_end_crashes > 0
        ) {
          marker = L.marker([element.location.lat, element.location.lng], {
            icon: rampRearEndIcon,
          })
            .bindTooltip("Count: " + element.ramp_rear_end_crashes)
            .addTo(this.map);
          this.incidentTypesMarkersGroup.addLayer(marker);
        } else if (
          this.selectedIncidentTypes.includes("ramp_single_vehicle_crashes") &&
          element.ramp_single_vehicle_crashes > 0
        ) {
          marker = L.marker([element.location.lat, element.location.lng], {
            icon: rampSingleVehicleIcon,
          })
            .bindTooltip("Count: " + element.ramp_single_vehicle_crashes)
            .addTo(this.map);
          this.incidentTypesMarkersGroup.addLayer(marker);
        } else if (
          this.selectedIncidentTypes.includes("wrong_way_crashes") &&
          element.wrong_way_crashes > 0
        ) {
          marker = L.marker([element.location.lat, element.location.lng], {
            icon: wrongWayIcon,
          })
            .bindTooltip("Count: " + element.wrong_way_crashes)
            .addTo(this.map);
          this.incidentTypesMarkersGroup.addLayer(marker);
        }
      });
    },
    plotVRUSpots: function (hotspots) {
      var bicyclistIcon = new this.cycleIcon({ iconSize: [36, 24] });
      var motorcyclistIcon = new this.motorbikeIcon({ iconSize: [30, 36] });
      var pedestrianIcon = new this.pedestrianIcon({ iconSize: [24, 24] });

      this.vruMarkersGroup.clearLayers();
      var marker = undefined;
      hotspots.forEach((element) => {
        if (this.selectedVRUs.includes("bicyclist") && element.bicyclist > 0) {
          marker = L.marker([element.location.lat, element.location.lng], {
            icon: bicyclistIcon,
          })
            .bindTooltip("Count: " + element.bicyclist)
            .addTo(this.map);
          this.vruMarkersGroup.addLayer(marker);
        } else if (
          this.selectedVRUs.includes("motorcyclist") &&
          element.motorcyclist > 0
        ) {
          marker = L.marker([element.location.lat, element.location.lng], {
            icon: motorcyclistIcon,
          })
            .bindTooltip("Count: " + element.motorcyclist)
            .addTo(this.map);
          this.vruMarkersGroup.addLayer(marker);
        } else if (
          this.selectedVRUs.includes("pedestrian") &&
          element.pedestrian > 0
        ) {
          marker = L.marker([element.location.lat, element.location.lng], {
            icon: pedestrianIcon,
          })
            .bindTooltip("Count: " + element.pedestrian)
            .addTo(this.map);
          this.vruMarkersGroup.addLayer(marker);
        }
      });
    },
    onHotspotClick: function (e) {
      var loc = this.topCrashSpotLeafetIdMapper[e.target._leaflet_id];

      if (this.$store.state.featureToggles["cav_messages"]) {
        console.log(this.crashSpots[loc].top_crash_spots);
        this.roadCharacteristics = {
          riskIndex: this.crashSpots[loc].top_crash_spots.risk_index,
          crashes: this.crashSpots[loc].top_crash_spots.crash_count,
          countermeasures: this.crashSpots[loc].top_crash_spots.countermeasures,
          cav_messages: this.crashSpots[loc].top_crash_spots.cav_messages,
        };
      } else {
        this.roadCharacteristics = {
          riskIndex: this.crashSpots[loc].top_crash_spots.risk_index,
          crashes: this.crashSpots[loc].top_crash_spots.crash_count,
          countermeasures: this.crashSpots[loc].top_crash_spots.countermeasures,
        };
      }

      this.location = {
        lat: Number.parseFloat(loc.split(", ")[0].substring(1)),
        lng: Number.parseFloat(
          loc.split(", ")[1].substring(0, loc.split(", ")[1].length - 1)
        ),
      };

      this.vruStatistics = {
        pedestrian: this.crashSpots[loc].vulnerable_road_users
          ? this.crashSpots[loc].vulnerable_road_users.pedestrian
            ? this.crashSpots[loc].vulnerable_road_users.pedestrian
            : 0
          : 0,
        bicyclist: this.crashSpots[loc].vulnerable_road_users
          ? this.crashSpots[loc].vulnerable_road_users.bicyclist
            ? this.crashSpots[loc].vulnerable_road_users.bicyclist
            : 0
          : 0,
        motorcyclist: this.crashSpots[loc].vulnerable_road_users
          ? this.crashSpots[loc].vulnerable_road_users.motorcyclist
            ? this.crashSpots[loc].vulnerable_road_users.motorcyclist
            : 0
          : 0,
      };

      this.displayDetailedView = true;
      this.$store.dispatch("updateSelectedMarker", e.target._leaflet_id);
    },
    getColor: function (riskIndex) {
      if (riskIndex <= 0.25) {
        return "#D3D620";
      } else if (riskIndex > 0.25 && riskIndex <= 0.5) {
        return "#FAD21C";
      } else if (riskIndex > 0.5 && riskIndex <= 0.75) {
        return "#FA7F28";
      } else {
        return "#B4061F";
      }
    },
    getRadius: function (crashCount) {
      var radius = 0;
      if (this.normalizedCrashCountMap[crashCount] > 5) {
        radius = this.normalizedCrashCountMap[crashCount] * 60;
      } else {
        radius = this.normalizedCrashCountMap[crashCount] * 90;
      }

      return radius;
    },
    closeDetailedView() {
      this.displayDetailedView = false;
      this.location = {};
      this.roadCharacteristics = {};
      this.vruStatistics = {};
      this.displayCountermeasures = false;
    },
    showCountermeasures(value) {
      this.displayCountermeasures = value;
    },
    makeToast(message, title, variant = "info") {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
        autoHideDelay: 1500,
      });
    },
    normalizeCrashCounts(counts, minValue, maxValue) {
      var max = Math.max.apply(Math, counts);
      var min = Math.min.apply(Math, counts);

      return counts.map(
        (num) => ((maxValue - minValue) * (num - min)) / (max - min) + minValue
      );
    },
    getDateRange() {
      let requestUrl = this.$store.state.apiUrl + "/hotspots/date-range/";

      axios
        .get(requestUrl)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.fromDate = response.data.minimum;
          this.fromDateMin = response.data.minimum;

          this.toDate = response.data.maximum;
          this.toDateMax = response.data.maximum;

          this.toDateMin = this.fromDateMax;
          this.fromDateMax = this.toDateMin;

          this.getHotspots();
        })
        .catch((err) => {
          console.log("Error: \n", err);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Could not fetch latest date",
            "danger"
          );
        });
    },
    hideStreetView() {
      this.$store.dispatch("updateShowStreetView", false);
    },
    getRoadClass() {
      let requestUrl = this.$store.state.apiUrl + "/road-class";

      axios
        .get(requestUrl)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          // Store a copy for future use
          this.$store.dispatch(
            "updateRoadClasses",
            response.data["road_classes"]
          );

          // Default selection will be - 'All Selected'
          this.$store.dispatch(
            "updateSelectedRoadClasses",
            response.data["road_classes"]
          );
        })
        .catch((err) => {
          console.log("Error: \n", err);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Could not fetch latest date",
            "danger"
          );
        });
    },
    filterHotspotsByJurisdiction(selectedJurisdiction) {
      var updateHotSpotSelection = [];
      this.topCrashSpots.forEach((element) => {
        if (
          element.road_classes.filter((value) =>
            selectedJurisdiction.includes(value)
          ).length > 0
        ) {
          updateHotSpotSelection.push(element);
        }
      });

      this.selectedTopCrashSpots = updateHotSpotSelection;
      // Only re-plot if the initial fetch is complete
      if (this.topCrashSpots.length > 0)
        this.plotTopCrashSpots(this.selectedTopCrashSpots);
    },
  },
  mounted() {
    this.getDateRange();
    this.getRoadClass();
  },
  beforeDestroy() {
    this.$store.dispatch("updateEnableHotSpotOptions", false);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#map {
  position: relative;
  height: calc(100vh - var(--top-nav-bar-height) - var(--footer-height));
  width: calc(100vw - var(--side-menu-width));
  z-index: 1004;
}

#street-view-photo-sphere {
  position: absolute;
  z-index: 1010;
  height: calc(100vh - var(--top-nav-bar-height) - var(--footer-height));
  width: calc(100vw - var(--side-menu-width));
  top: calc(var(--top-nav-bar-height));
  left: calc(var(--side-menu-width));
}

#close-street-view-icon {
  position: absolute;
  z-index: 1012;
  height: 40px;
  width: 40px;
  border-radius: 2px;
  top: calc(var(--top-nav-bar-height) + 55px);
  right: 10px;
  background: black;
  cursor: pointer;
}

#close-overlay {
  color: var(--close-overlay-color);
}

#close-overlay:hover {
  color: var(--close-overlay-hover-color);
  cursor: pointer;
}

#loading {
  position: absolute;
  top: 50%;
  color: #009999;
}

#date-selector {
  position: absolute;
  width: 400px;
  top: 65px;
  right: 0;
  display: flex;
  justify-content: space-evenly;
}

.date-picker {
  max-width: 180px;
  z-index: 1006;
}

#countermeasures {
  position: absolute;
  //width: 550px;
  bottom: calc(var(--footer-height) + 8px);
  left: calc(var(--side-menu-width) + 10px);
  display: flex;
  justify-content: space-evenly;
}

.cm-child {
  width: 250px;
  height: 200px;
  z-index: 1006;
  background-color: white;
  margin-right: 10px;
}

.cm-child-header {
  height: 30px;
  font-size: 18px;
  text-align: left;
  font-weight: 550;
  color: white;
  background-color: #00e6dc;
  padding: 2.5px 0 0 10px;
}

.cav {
  background-color: #2c3e50;
}

.cm-child-body {
  margin: 10px;
  height: 150px;
  overflow-y: auto;
  margin: 10px;
}

.cm-child-body-recommendation {
  text-align: left;
  font-weight: 600;
  white-space: pre-line;
}

.cm-child-body-accuracy {
  display: flex;
}

.cm-child-body-compliance {
  bottom: 0;
  margin: 10px 0 10px 0;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  white-space: pre-line;
}

.cm-child-body-optional {
  bottom: 0;
  margin: 10px 0 10px 0;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  white-space: pre-line;
}

.accuracy-title {
  margin: 10px 0px 10px 0px;
  text-align: left;
  font-weight: 600;
}

.accuracy-value {
  margin: 10px 0 10px 10px;
}

.cm-child-body-ul {
  padding-left: 20px;
}
</style>
