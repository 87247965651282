<template>
  <div id="app">
    <div class="top-nav-bar">
      <b-navbar toggleable="lg" type="light" variant="dark">
        <b-navbar-brand href="#">
          <div id="product-title">
            <div id="siemens-new-design"></div>
            <span class="title-text"> Crash Hotspots </span>
          </div>
        </b-navbar-brand>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown right>
              <template v-slot:button-content>
                <span class="top-nav-bar-text"> powered by </span>
                <b-icon-blank style="width: 1px;" />
                <span class="siemens-logo"> s </span>

                <b-icon-blank style="width: 5px;" />
                <b-icon
                  class="circle-icon"
                  variant="light"
                  icon="person-circle"
                ></b-icon>
              </template>
              <b-dropdown-item id="signout" @click="logout"
                >Sign Out
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>

    <div class="side-nav">
      <b-nav vertical variant="dark">
        <b-nav-item active class="h1 mb-2">
          <router-link to="/">
            <div class="side-menu-modules">
              <b-icon
                icon="map-fill"
                font-scale="1"
                variant="secondary"
                class="side-menu-icon"
              />
              <div v-if="displayText" class="side-menu-icon-text">
                Top Hotspots
              </div>
            </div>
          </router-link>
        </b-nav-item>

        <div v-if="displayText" class="side-menu-text-options">
          <div v-if="currentRoute === '/'" class="side-sub-menu">
            <div class="options">
              <div class="options-header">
                <div class="options-header-title">
                  Incident Type
                </div>
                <div class="options-header-toggle">
                  <b-form-checkbox
                    v-model="incidentTypesEnabled"
                    switch
                    :disabled="!this.$store.state.enableHotSpotOptions"
                  />
                </div>
              </div>
              <hr class="solid-line" />
              <div>
                <b-form-checkbox-group
                  id="checkbox-group-incident-types"
                  v-model="incidentTypeSelections"
                  :options="incidentTypeOptions"
                  :disabled="!this.$store.state.enableHotSpotOptions"
                  ><div class="ww-tooltip">
                    <b-icon
                      icon="info-circle-fill"
                      font-scale="0.9"
                      shift-h="-15"
                      shift-v="-1.5"
                    />
                    <span class="ww-tooltiptext">
                      Wrong-way driving is an estimated feature calculated from
                      crash type and traffic way data
                    </span>
                  </div>
                </b-form-checkbox-group>
              </div>
            </div>
            <div class="options">
              <div class="options-header">
                <div class="options-header-title">
                  Vulnerable Road Users
                </div>
                <div class="options-header-toggle">
                  <b-form-checkbox
                    v-model="vrusEnabled"
                    switch
                    :disabled="!this.$store.state.enableHotSpotOptions"
                  />
                </div>
              </div>
              <hr class="solid-line" />
              <div>
                <b-form-checkbox-group
                  id="checkbox-group-vru"
                  v-model="vruSelections"
                  :options="vruOptions"
                  :disabled="!this.$store.state.enableHotSpotOptions"
                  stacked
                ></b-form-checkbox-group>
              </div>
            </div>
            <div class="options">
              <div class="options-header">
                <div class="options-header-title">
                  Road Class
                </div>
                <div class="options-header-toggle">
                  <b-form-checkbox
                    v-model="roadClassEnabled"
                    switch
                    :disabled="!this.$store.state.enableHotSpotOptions"
                  />
                </div>
              </div>
              <hr class="solid-line" />
              <div>
                <b-form-checkbox-group
                  id="checkbox-group-road-class"
                  v-model="selectedRoadClasses"
                  :options="this.$store.state.roadClasses"
                  :disabled="!this.$store.state.enableHotSpotOptions"
                  stacked
                >
                </b-form-checkbox-group>
              </div>
            </div>
          </div>
        </div>

        <b-nav-item active class="h1 mb-2" v-if="dashboardEnabled">
          <router-link to="/dashboard">
            <div class="side-menu-modules">
              <b-icon
                icon="pie-chart-fill"
                font-scale="1.3"
                variant="secondary"
                class="side-menu-icon"
              />
              <div v-if="displayText" class="side-menu-icon-text">
                Dashboard
              </div>
            </div>
          </router-link>
        </b-nav-item>

        <b-nav-item
          v-if="this.$keycloak.idTokenParsed['ch-role'] === 'admin'"
          active
          class="h1 mb-2"
        >
          <router-link to="/admin">
            <div class="side-menu-modules">
              <b-icon
                icon="person-badge"
                font-scale="1.3"
                variant="secondary"
                class="side-menu-icon"
              />
              <div v-if="displayText" class="side-menu-icon-text">
                Admin
              </div>
            </div>
          </router-link>
        </b-nav-item>
      </b-nav>
      <div>
        <div v-if="displayText" @click="toggleDisplayText">
          <b-icon icon="chevron-double-left" font-scale="2" variant="dark" />
        </div>
        <div v-else @click="toggleDisplayText" class="chevron-icon">
          <b-icon
            icon="chevron-double-right"
            font-scale="2"
            variant="dark"
          ></b-icon>
        </div>
      </div>
    </div>

    <div class="map-container">
      <!-- component matched by the route will render here -->
      <router-view />
    </div>

    <div
      class="footer-copyright d-flex bg-dark text-light align-items-center px-3 py-2"
    >
      © Siemens Mobility {{ currentYear }}
    </div>
    <div
      class="footer-links d-flex bg-dark text-light align-items-center px-3 py-2"
    >
      <div class="footer">
        <a href="ReadMeOSS_Crash_Hotspots_1.0.0_20210407.html" target="_blank"
          >ReadMe</a
        >
      </div>
      <div class="footer">
        <a href="http://www.siemens.com/digital_id_en" target="_blank"
          >Digital ID</a
        >
      </div>
      <div class="footer">
        <a href="http://www.siemens.com/terms_of_use" target="_blank"
          >Terms of Use</a
        >
      </div>
      <div class="footer">
        <a
          href="https://new.siemens.com/global/en/general/legal.html#CookieNotice"
          target="_blank"
          >Cookie Notice</a
        >
      </div>
      <div class="footer">
        <a href="http://www.siemens.com/privacy" target="_blank"
          >Privacy Policy</a
        >
      </div>
      <div class="footer">
        <a href="http://www.siemens.com/corporate_info" target="_blank"
          >Corporate Information</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "App",
  props: [],
  data() {
    return {
      currentYear: new Date().getFullYear(),
      displayText: true,
      incidentTypesEnabled: false,
      vrusEnabled: false,
      roadClassEnabled: true,
      incidentTypeSelections: [],
      incidentTypeOptions: [
        { text: "Rear-end (Ramp)", value: "ramp_rear_end_crashes" },
        {
          text: "Single Vehicle (Ramp)",
          value: "ramp_single_vehicle_crashes"
        },
        { text: "Wrong-way driving", value: "wrong_way_crashes" }
      ],
      vruSelections: [],
      vruOptions: [
        {
          text: "Motorcyclists",
          value: "motorcyclist"
        },
        { text: "Bicyclists", value: "bicyclist" },
        {
          text: "Pedestrians",
          value: "pedestrian"
        }
      ]
    };
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    ...mapState(["selectedIncidentTypes", "selectedVRUs"]),
    selectedRoadClasses: {
      set(value) {
        this.$store.commit("updateSelectedRoadClasses", value);
      },
      get() {
        return this.$store.state.selectedRoadClasses;
      }
    },
    dashboardEnabled() {
      const domainsToDisableDashboard = ['dot.state.fl.us', 'ce.ufl.edu'];
      const userShouldHaveDashboardDisabled = domainsToDisableDashboard.some(domain => this.$keycloak.idTokenParsed['email'].includes(domain));
      return !userShouldHaveDashboardDisabled;
    }
  },
  watch: {
    displayText: function() {
      if (this.displayText) {
        this.updateSideBarWidth(250);
      } else {
        this.updateSideBarWidth(45);
      }
    },
    incidentTypeSelections: function() {
      this.$store.dispatch(
        "updateSelectedIncidentTypes",
        this.incidentTypeSelections
      );

      if (this.incidentTypeSelections.length > 0) {
        this.incidentTypesEnabled = true;
      } else if (this.incidentTypeSelections.length === 0) {
        this.incidentTypesEnabled = false;
      }
    },
    vruSelections: function() {
      this.$store.dispatch("updateSelectedVRUs", this.vruSelections);

      if (this.vruSelections.length > 0) {
        this.vrusEnabled = true;
      } else if (this.vruSelections.length === 0) {
        this.vrusEnabled = false;
      }
    },
    incidentTypesEnabled: function(value) {
      if (value && this.incidentTypeSelections.length === 0) {
        this.incidentTypeSelections = [
          "ramp_rear_end_crashes",
          "ramp_single_vehicle_crashes",
          "wrong_way_crashes"
        ];
      } else if (!value) {
        this.incidentTypeSelections = [];
      }
      this.$store.dispatch(
        "updateSelectedIncidentTypes",
        this.incidentTypeSelections
      );
    },
    vrusEnabled: function(value) {
      if (value && this.vruSelections.length === 0) {
        this.vruSelections = ["motorcyclist", "bicyclist", "pedestrian"];
      } else if (!value) {
        this.vruSelections = [];
      }
      this.$store.dispatch("updateSelectedVRUs", this.vruSelections);
    },
    roadClassEnabled: function(value) {
      if (!value) {
        this.$store.dispatch("updateSelectedRoadClasses", []);
      } else {
        // Resstore using the copy
        this.$store.dispatch(
          "updateSelectedRoadClasses",
          this.$store.state.roadClasses
        );
      }
    }
  },
  methods: {
    logout: function() {
      this.$router.push({ name: "Mapper" });
      this.$keycloak.logoutFn();
    },
    // Update the style of the root DOM element that the Vue instance is managing
    updateCSSVariable: function(name, value) {
      this.$el.style.setProperty(name, value);
    },
    updateSideBarWidth: function(width) {
      this.updateCSSVariable("--side-menu-width", `${width}px`);
    },
    toggleDisplayText: function() {
      this.displayText = !this.displayText;
    }
  }
};
</script>

<style lang="scss">
#app {
  z-index: 1000;
  font-family: "Siemens Sans Roman", Arial, helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  grid-template-areas:
    "top-nav-bar top-nav-bar top-nav-bar top-nav-bar top-nav-bar top-nav-bar"
    "side-menu main main main main main"
    "footer-copyright footer-copyright footer-copyright footer-links footer-links footer-links";
}

@font-face {
  font-family: "Siemens Sans Roman";
  src: url("assets/fonts/siemenssans-roman-webfont.woff2") format("woff2"),
    url("assets/fonts/siemenssans-roman-webfont.woff") format("woff"),
    url("assets/fonts/siemenssans-roman-webfont.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Siemens Sans Bold";
  src: url("assets/fonts/siemenssans-bold-webfont.woff2") format("woff2"),
    url("assets/fonts/siemenssans-bold-webfont.woff") format("woff"),
    url("assets/fonts/siemenssans-bold-webfont.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Siemens Logo Regular";
  src: url("assets/fonts/silogo-webfont.woff2") format("woff2"),
    url("assets/fonts/silogo-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.regular {
  font-family: "Siemens Sans Roman", Arial, helvetica, sans-serif;
}

.bold {
  font-family: "Siemens Sans Bold", Arial, helvetica, sans-serif;
}

.siemens-logo {
  font-size: 28px;
  color: #009999;
  font-family: "Siemens Logo Regular", sans-serif;
}

/* set the z-index above than the side bar card */
.nav-item.b-nav-dropdown.dropdown.show {
  z-index: 1003;
}

/* Scrollbar begin */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* Scrollbar end */

:root {
  --top-nav-bar-height: 58px;
  --side-menu-width: 250px;
  --footer-height: 32px;
  --admin-tab-height: 108px;
  overflow: hidden;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
  }
}

.top-nav-bar {
  grid-area: top-nav-bar;
}

.top-nav-bar > .navbar {
  padding: 0;
}

.navbar-nav {
  padding-bottom: 0;
}

.circle-icon {
  margin: 5px;
}

// responsible for the dropdown arrow next to the person icon
#nav-collapse > .navbar-nav a[role="button"]::after {
  color: #f8f9fa;
  margin: 4px 4px 4px 0;
}

.top-nav-bar-text {
  font-size: 12px;
  color: white;
  padding-bottom: 100px;
}

.title-text {
  font-size: 25px;
  color: white;
  font-family: sans-serif;
  padding-left: 10px;
}

#product-title {
  display: flex;
  justify-content: center;
}

#siemens-new-design {
  background-image: linear-gradient(#00ffb9, #00e6dc);
  height: 30px;
  width: 2.5px;
  margin: 4px 0 0 10px;
}

.side-nav {
  grid-area: side-menu;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: var(--side-menu-width);
  min-height: calc(100vh - var(--top-nav-bar-height) - var(--footer-height));
  background: white;
  border-right: 2px solid #2c3e50;
}

#app > div.side-nav > ul > li > a {
  padding: 0px;
}

#app > div.side-nav > ul > li:nth-child(n) {
  margin: 0px !important;
}

.side-menu-modules {
  font-size: 20px;
  display: grid;
  background: #00cccc1f;
  padding: 5px;
  grid-template-columns: 30px auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

/* Update Incident Type and VRU toggle active background color */
.options-header-toggle
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #0098a6 !important;
}

/* Update checkbox colors (Incident Type) to match the icon color - start */
#checkbox-group-incident-types
  > div:nth-child(1)
  > .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: #9607f8ff;
  background-color: #9607f8ff;
  color: #9607f8ff;
}

#checkbox-group-incident-types
  > div:nth-child(2)
  > .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: #a7b946;
  background-color: #a7b946;
  color: #a7b946;
}

#checkbox-group-incident-types
  > div:nth-child(3)
  > .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: #b9466dff;
  background-color: #b9466dff;
  color: #b9466dff;
}
/* Update checkbox colors (Incident Type) to match the icon color - end */

/* Update checkbox colors (Vulnerable Road Users) to match the icon color - start */
#checkbox-group-vru
  > div:nth-child(1)
  > .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: #5b2c6f;
  background-color: #5b2c6f;
  color: #5b2c6f;
}

#checkbox-group-vru
  > div:nth-child(2)
  > .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: #e67e22;
  background-color: #e67e22;
  color: #e67e22;
}

#checkbox-group-vru
  > div:nth-child(3)
  > .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: #2c6f5b;
  background-color: #2c6f5b;
  color: #2c6f5b;
}
/* Update checkbox colors (Vulnerable Road Users) to match the icon color - end */

/* cm-tooltip container */
.ww-tooltip {
  position: relative;
  display: inline-block;
}

/* cm-tooltip text */
.ww-tooltip .ww-tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #6c757d;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding: 10px;
  font-size: 12px;
  font-weight: 500;

  /* Position the cm-tooltip text */
  position: absolute;
  z-index: 5000;
  bottom: 125%;
  left: 0%;
  margin-left: -150px;

  /* Fade in cm-tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* cm-tooltip arrow */
.ww-tooltip .ww-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  right: 25%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #6c757d transparent transparent transparent;
}

/* Show the cm-tooltip text when you mouse over the cm-tooltip container */
.ww-tooltip:hover .ww-tooltiptext {
  visibility: visible;
  opacity: 1;
}

.side-menu-icon {
  margin: 0 auto;
}

.side-menu-icon-text {
  font-size: 20px;
  // color: #00708c;
  color: #6c757d;
  text-decoration: none;
  text-align: left;
  margin: 0 0 0 4px;
  padding-left: 5px;
}

.side-menu-text-options {
  font-size: 16px;
  text-decoration: none;
  text-align: left;
}

.options {
  background-color: #00cccc0f;
  padding: 0 0 5px 5px;
}

.options-header {
  display: flex;
  justify-content: space-between;
}

.options-header-title {
  padding: 2px;
  font-size: 18px;
  font-weight: 550;
}

.options-header-toggle {
  padding: 2px;
}

#checkbox-group-incident-types,
#checkbox-group-vru {
  margin-left: 2.5px;
}

/* Remove underline from router links on hover start */
.router-link-options {
  text-decoration: none;
}

li a:hover {
  text-decoration: none;
}
/* Remove underline from router links on hover end */

/* Change color on selecting side menu item start */
.router-link-exact-active .side-menu-icon-text {
  color: #0099bf;
  text-decoration: none;
  font-weight: 600;
}

// 'important' tag is needed to override the SVG color
.router-link-exact-active .side-menu-icon {
  color: #0099bf !important;
  font-weight: 600;
}
/* Change color on selecting side menu item end */

.map-container {
  grid-area: main;
  width: calc(100vw - var(--side-menu-width));
  z-index: 995;
}

.options-header-toggle > div > label::before {
  background-color: white;
}

.options-header > div.options-header-toggle > div > label::after {
  border-color: #0098a6;
  background-color: #0098a6;
  color: #0098a6;
}

#checkbox-group-incident-types > div {
  margin-bottom: 5px;
}

#checkbox-group-vru > div {
  margin-bottom: 5px;
}

.footer-copyright {
  min-height: 32px;
  max-height: 32px;
  grid-area: footer-copyright;
  font-size: 14px;
}

.footer-links {
  min-height: 32px;
  max-height: 32px;
  font-size: 14px;
  grid-area: footer-links;
  justify-content: flex-end;
}

.footer-links * a {
  text-decoration: none;
  color: white;
}

.footer-links * a:hover {
  text-decoration: none;
  color: white;
}

.footer {
  padding-left: 15px;
}

.chevron-icon {
  width: 100%;
}

/* Solid horizontal divider */
hr.solid-line {
  border-top: 2px solid white;
  margin: 2px 4px 2px 0;
}

#signout {
  z-index: 5000;
}
</style>
