<template>
  <div id="side-bar-container">
    <b-spinner
      v-if="loadingStats"
      id="loading"
      type="grow"
      label="Loading..."
    ></b-spinner>
    <div v-else id="panel">
      <div id="detail-header">
        <div id="detail-header-text">
          <div id="header-title">Detailed View</div>
          <b-icon
            @click="closeDetailedView"
            icon="x-square"
            font-scale="1.5"
            id="close-overlay"
          >
          </b-icon>
        </div>
      </div>

      <div id="detail-header-icon">
        <b-icon
          icon="gear"
          font-scale="1.5"
          style="color: #009999; width: 100px; height: 90px;"
        >
        </b-icon>
        <b-icon
          icon="gear-fill"
          shift-v="7.5"
          shift-h="-4"
          rotate="-1"
          style="color: #009999; width: 100px; height: 90px;"
        ></b-icon>
      </div>

      <div id="location">
        <div id="location-label">Location:</div>
        <hr class="solid-line" />
        <div id="location-value">
          {{ this.statistics.road_statistics.address }}
        </div>
      </div>

      <div id="detailed-view-body">
        <div>
          <div class="body-subtitle">
            <div>
              Incident Statistics
            </div>
            <div>
              <b-icon
                class="h5"
                :icon="incidentStatsCollapse ? 'chevron-up' : 'chevron-down'"
                @click="incidentStatsCollapse = !incidentStatsCollapse"
              ></b-icon>
            </div>
          </div>
          <hr class="solid-line" />
          <b-collapse
            id="incident-stats-collapse"
            v-model="incidentStatsCollapse"
          >
            <div id="body-text">
              <div class="body-text-row">
                <div class="body-text-label">
                  Crash Count:
                </div>
                <div class="body-text-value">
                  {{ this.roadCharacteristics.crashes }}
                </div>
              </div>
              <div class="body-text-row">
                <div class="body-text-label">
                  Risk Index:
                </div>
                <div class="body-text-value">
                  {{
                    Number.parseFloat(
                      this.roadCharacteristics.riskIndex
                    ).toPrecision(2)
                  }}
                </div>
              </div>
              <div class="body-text-row">
                <div class="body-text-label">
                  Fatalities:
                </div>
                <div class="body-text-value">{{ fatalityPercentage }} %</div>
              </div>
              <div class="body-text-row">
                <div class="body-text-label">
                  Serious Injuries:
                </div>
                <div class="body-text-value">
                  {{ seriousInjuryPercentage }} %
                </div>
              </div>
              <div class="body-text-row">
                <div class="body-text-label">
                  Pedestrians Involved:
                </div>
                <div class="body-text-value">{{ pedestriansPercentage }} %</div>
              </div>
              <div class="body-text-row">
                <div class="body-text-label">
                  Bicyclists Involved:
                </div>
                <div class="body-text-value">{{ bicyclistsPercentage }} %</div>
              </div>
              <div class="body-text-row">
                <div class="body-text-label">
                  Motorcyclists Involved:
                </div>
                <div class="body-text-value">
                  {{ motorcyclistsPercentage }} %
                </div>
              </div>
              <div class="body-text-row">
                <div class="body-text-label crash-types-label">
                  Crash Types:
                </div>
                <div class="body-text-value">
                  <b-button-group
                    class="info-btn"
                    horizontal="sm"
                    flushed
                    v-for="item in statistics.road_statistics.crash_type"
                    :key="item"
                  >
                    {{ item }}
                  </b-button-group>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>

        <div>
          <div class="body-subtitle">
            <div class="mt-2">
              Cost Statistics
            </div>
            <div>
              <b-icon
                class="h5 mt-2"
                :icon="costStatsCollapse ? 'chevron-up' : 'chevron-down'"
                @click="costStatsCollapse = !costStatsCollapse"
              ></b-icon>
            </div>
          </div>
          <hr class="solid-line" />
          <b-collapse id="cost-stats-collapse" v-model="costStatsCollapse">
            <div id="body-text">
              <div class="body-text-row">
                <div class="body-text-label">
                  Average Human Capital Cost:
                </div>
                <div class="body-text-value">
                  {{
                    this.beautifyCostStatistic(
                      this.statistics.cost_statistics.avg_human_capital_cost
                    )
                  }}
                </div>
              </div>
              <div class="body-text-row">
                <div class="body-text-label">
                  Total Human Capital Cost:
                </div>
                <div class="body-text-value">
                  {{
                    this.beautifyCostStatistic(
                      this.statistics.cost_statistics.total_human_capital_cost
                    )
                  }}
                </div>
              </div>
              <div class="body-text-row">
                <div class="body-text-label">
                  Average Comprehensive Cost:
                </div>
                <div class="body-text-value">
                  {{
                    this.beautifyCostStatistic(
                      this.statistics.cost_statistics.avg_comprehensive_cost
                    )
                  }}
                </div>
              </div>
              <div class="body-text-row">
                <div class="body-text-label">
                  Total Comprehensive Cost:
                </div>
                <div class="body-text-value">
                  {{
                    this.beautifyCostStatistic(
                      this.statistics.cost_statistics.total_comprehensive_cost
                    )
                  }}
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>

      <div
        :id="
          absoluteFooterPlacement
            ? 'detailed-view-footer-absolute'
            : 'detailed-view-footer'
        "
      >
        <div class="countermeasures">
          <div class="footer-label">
            Countermeasures (CM)
            <div class="cm-tooltip">
              <b-icon icon="info-circle-fill" font-scale="0.9" shift-h="4" />
              <span class="cm-tooltiptext">
                These recommneded countermeasures are proposed by FHWA & CMF.
                Siemens does not control, modify or influence the FHWA or CMF
                proposals. Siemens relinquishes any and all liability for the
                recommendations provided by the FHWA & CMF.
              </span>
            </div>
          </div>
          <div id="countermeasures-toggle">
            <b-form-checkbox
              v-model="showCountermeasures"
              switch
            ></b-form-checkbox>
          </div>
        </div>

        <div class="street-view">
          <div class="footer-label">
            Street View
          </div>

          <div id="street-view-toggle">
            <b-form-checkbox switch v-model="showStreetView"></b-form-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "MapSideBar",
  data() {
    return {
      statistics: {},
      loadingStats: true,
      absoluteFooterPlacement: false,
      showCountermeasures: false,
      incidentStatsCollapse: true,
      costStatsCollapse: false,
    };
  },
  props: {
    location: Object,
    roadCharacteristics: Object,
    vruStatistics: Object
  },
  computed: {
    ...mapState(["selectedMarker", "showStreetView"]),
    showStreetView: {
      get() {
        return this.$store.state.showStreetView;
      },
      set(value) {
        this.$store.dispatch("updateShowStreetView", value);
      }
    },
    fatalityPercentage() {
      console.log(this.roadCharacteristics);
      var percent =
        (this.statistics.incident_statistics.fatalities /
          this.roadCharacteristics.crashes) *
        100;
      return percent.toFixed(0);
    },
    seriousInjuryPercentage() {
      console.log(this.statistics.incident_statistics)
      var percent =
        (this.statistics.incident_statistics.serious_injuries /
          this.roadCharacteristics.crashes) *
        100; 
      return percent.toFixed(0);
    },
    pedestriansPercentage() {
      var percent =
        (this.vruStatistics.pedestrian / this.roadCharacteristics.crashes) *
        100;
      return percent.toFixed(0);
    },
    bicyclistsPercentage() {
      var percent =
        (this.vruStatistics.bicyclist / this.roadCharacteristics.crashes) * 100;
      return percent.toFixed(0);
    },
    motorcyclistsPercentage() {
      var percent =
        (this.vruStatistics.motorcyclist / this.roadCharacteristics.crashes) *
        100;
      return percent.toFixed(0);
    }
  },
  watch: {
    incidentStatsCollapse() {
      if (this.incidentStatsCollapse) {
        this.costStatsCollapse = false;
      }

      // Keep the toggle options at the bottom of the panel if the
      // collapse options are closed
      // otherwise place them below the previous div
      if (!this.incidentStatsCollapse && !this.costStatsCollapse) {
        this.absoluteFooterPlacement = true;
      } else {
        this.absoluteFooterPlacement = false;
      }
    },
    costStatsCollapse() {
      if (this.costStatsCollapse) {
        this.incidentStatsCollapse = false;
      }

      // Keep the toggle options at the bottom of the panel if the
      // collapse options are closed
      // otherwise place them below the previous div
      if (!this.incidentStatsCollapse && !this.costStatsCollapse) {
        this.absoluteFooterPlacement = true;
      } else {
        this.absoluteFooterPlacement = false;
      }
    },
    selectedMarker() {
      this.getStatistics();
    },
    showCountermeasures() {
      this.$emit("showCountermeasures", this.showCountermeasures);
    }
  },
  methods: {
    beautifyCostStatistic(val) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      });

      return formatter.format(val);
    },
    getStatistics: function() {
      this.loadingStats = true;

      let requestUrl = this.$store.state.apiUrl + "/statistics/";
      requestUrl += "?lat=" + this.location.lat + "&lng=" + this.location.lng;

      axios
        .get(requestUrl)
        .then(response => {
          this.statistics = response.data;

          this.loadingStats = false;
        })
        .catch(err => console.log(err));
    },
    closeDetailedView() {
      this.loadingStats = true;
      this.$emit("closeDetailedView");
    }
  },
  mounted() {
    this.getStatistics();
  }
};
</script>

<style scoped lang="scss">
#side-bar-container {
  position: fixed;
  margin: 0 15px 0 0;
  z-index: 1006;

  height: 65vh;
  width: 370px;
  top: 11vh;
  right: 0;

  font-weight: 600;
  background: #ebffff;

  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  overflow-y: auto;
}

#panel {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 15px;
}

#detail-header-text {
  height: 80px;
  display: flex;
  justify-content: space-between;
}

#detail-header-icon {
  height: 100%;
}

#loading {
  position: absolute;
  top: 50%;
  color: #009999;
}

#header-title {
  margin: 5px 5px 5px 10px;
  font-size: 20px;
}

#close-overlay {
  margin: 7.5px 10px 5px 5px;
  cursor: pointer;
}

#location-label {
  margin: 0 5px 12.5px 10px;
  font-weight: 700;
  display: flex;
}

#location-value {
  margin: 5px 5px 5px 10px;
  font-weight: 500;
  display: flex;
}

.body-subtitle {
  margin: 0 15px 5px 10px;
  display: flex;
  justify-content: space-between;
}

#body-text {
  margin: 0 5px 5px 10px;
  font-weight: 500;
  text-align: left;
}

.body-text-row {
  display: flex;
}

.crash-types-label {
  min-width: 50px;
}

.info-btn {
  margin: 0 2px 2px 0;
  background-color: #484848;
  color: white;
  border: none;
  padding: 4px;
  border-radius: 4px;
  font-size: 11px;
  text-align: center;
  text-decoration: none;
}

.body-text-label {
  margin: 5px 0 0 5px;
  font-size: 15px;
  font-weight: 600;
}

.body-text-value {
  margin: 5px 0 0 5px;
  font-size: 15px;
  font-weight: 500;
}

#detailed-view-body {
  min-height: 25vh;
}

#detailed-view-footer {
  margin-top: 2%;
  margin-bottom: 2px;
}

#detailed-view-footer-absolute {
  position: absolute;
  width: 100%;
  bottom: 0;
  margin-bottom: 5px;
}

.footer-label {
  margin-left: 10px;
  margin-right: 15px;
  display: flex;
  justify-content: space-between;
}

/* cm-tooltip container */
.cm-tooltip {
  position: relative;
  display: inline-block;
}

/* cm-tooltip text */
.cm-tooltip .cm-tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #6c757d;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding: 15px;
  font-size: 12px;
  font-weight: 500;

  /* Position the cm-tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in cm-tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* cm-tooltip arrow */
.cm-tooltip .cm-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 25%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #6c757d transparent transparent transparent;
}

/* Show the cm-tooltip text when you mouse over the cm-tooltip container */
.cm-tooltip:hover .cm-tooltiptext {
  visibility: visible;
  opacity: 1;
}

.countermeasures {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

#countermeasures-toggle {
  margin-right: 5px;
}

.street-view {
  display: flex;
  justify-content: space-between;
}

#street-view-toggle {
  margin-right: 5px;
}

/* Update switch colors - start */
/* Change switch background color when active */
.countermeasures .custom-control-input:checked ~ .custom-control-label::before,
.street-view .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0098a6 !important;
}

/* Change toggle button (circl) color when active */
.countermeasures .custom-control-input:checked ~ .custom-control-label::after,
.street-view .custom-control-input:checked ~ .custom-control-label::after {
  background-color: white !important;
}

/* Change switch background color when disabled */
.countermeasures > div > label::before,
.street-view > div > label::before {
  background-color: white;
}

/* Change toggle button (circle) color when disabled */
.countermeasures > div#countermeasures-toggle > div > label::after,
.street-view > div#street-view-toggle > div > label::after {
  border-color: #0098a6;
  background-color: #0098a6;
  color: #0098a6;
}
/* Update switch colors - end */

/* Solid horizontal divider */
hr.solid-line {
  border-top: 2px solid grey;
  margin: 4px;
}
</style>
