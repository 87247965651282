import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// function checkFeatureIsEnabledInUrl(featureName) {
//   const urlParams = new URLSearchParams(window.location.search);
//   console.log(urlParams.get(featureName) === 'true')
//   return urlParams.get(featureName) === 'true';
// }

export default new Vuex.Store({
  state: {
    apiUrl: process.env.VUE_APP_API_URL,
    enableHotSpotOptions: false,
    selectedIncidentTypes: [],
    selectedVRUs: [],
    selectedRoadClasses: [],
    roadClasses: [],
    selectedMarker: undefined,
    showStreetView: false,
    featureToggles: {
      'cav_messages': true,
      'new_recommender': true
    }
  },
  mutations: {
    updateSelectedIncidentTypes(state, payload) {
      state.selectedIncidentTypes = payload;
    },
    updateEnableHotSpotOptions(state, payload) {
      state.enableHotSpotOptions = payload;
    },
    updateSelectedVRUs(state, payload) {
      state.selectedVRUs = payload;
    },
    updateSelectedRoadClasses(state, payload) {
      state.selectedRoadClasses = payload;
    },
    updateRoadClasses(state, payload) {
      state.roadClasses = payload;
    },
    updateSelectedMarker(state, payload) {
      state.selectedMarker = payload;
    },
    updateShowStreetView(state, payload) {
      state.showStreetView = payload;
    }
  },
  actions: {
    updateSelectedIncidentTypes(context, payload) {
      context.commit("updateSelectedIncidentTypes", payload);
    },
    updateEnableHotSpotOptions(context, payload) {
      context.commit("updateEnableHotSpotOptions", payload);
    },
    updateSelectedVRUs(context, payload) {
      context.commit("updateSelectedVRUs", payload);
    },
    updateSelectedRoadClasses(context, payload) {
      context.commit("updateSelectedRoadClasses", payload);
    },
    updateRoadClasses(context, payload) {
      context.commit("updateRoadClasses", payload);
    },
    updateSelectedMarker(context, payload) {
      context.commit("updateSelectedMarker", payload);
    },
    updateShowStreetView(context, payload) {
      context.commit("updateShowStreetView", payload);
    }
  },
  modules: {}
});
